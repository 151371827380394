import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { jwtDecode } from "jwt-decode";
import { environment } from "src/environments/environment";
import { extractValuesFromJson } from "../utilities/decodeCloudfrontToken";

@Injectable({
  providedIn: "root",
})
export class CloudFrontService {
  private refreshTokenTimeout: any;
  private cloudFrontPolicy: string;
  private cloudFrontSignature: string;
  private cloudFrontKeyPairId: string;
  user$: any;
  baseUrlAuth = environment.backend.baseUrlIam;

  constructor(private http: HttpClient, private cookieService: CookieService) {}

  getSignedCookies() {
    const existingPolicy = this.cookieService.get("CloudFront-Policy");
    if (existingPolicy) {
      const expirationDate = this.getExpirationDateFromPolicy(existingPolicy);
      const currentTime = new Date().getTime();

      if (expirationDate.getTime() > currentTime) {
        this.cloudFrontPolicy = existingPolicy;
        this.cloudFrontSignature = this.cookieService.get(
          "CloudFront-Signature"
        );
        this.cloudFrontKeyPairId = this.cookieService.get(
          "CloudFront-Key-Pair-Id"
        );
        this.scheduleTokenRefresh(expirationDate);
        return;
      }
    } else this.fetchNewSignedCookies();
  }

  private fetchNewSignedCookies() {
    const accessToken = this.cookieService.get("access_token");

    if (!accessToken) {
      console.error("No access token available.");
      return;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    });

    this.user$ = this.decodeToken(accessToken);

    this.http
      .get(
        `${this.baseUrlAuth}/client/${this.user$.country_code}/signed_cookies`,
        { headers }
      )
      .subscribe((response: any) => {
        this.cloudFrontPolicy = response["CloudFront-Policy"];
        this.cloudFrontSignature = response["CloudFront-Signature"];
        this.cloudFrontKeyPairId = response["CloudFront-Key-Pair-Id"];
        this.cookieService.set("CloudFront-Policy", this.cloudFrontPolicy, {
          path: "/",
          sameSite: "None",
           secure: true,
           domain:".m1dns.com"
        });
        this.cookieService.set(
          "CloudFront-Signature",
          this.cloudFrontSignature,
          {
            path: "/",
            sameSite: "None",
             secure: true,
             domain:".m1dns.com"
          }
        );
        this.cookieService.set(
          "CloudFront-Key-Pair-Id",
          this.cloudFrontKeyPairId,
          {
            path: "/",
            sameSite: "None",
             secure: true,
             domain:".m1dns.com"
          }
        );

        const expirationDate = this.getExpirationDateFromPolicy(
          this.cloudFrontPolicy
        );
        this.scheduleTokenRefresh(expirationDate);
      });
  }

  getExpirationDateFromPolicy(policy: string): Date {
    const decodedPolicy = extractValuesFromJson(policy);
    const expirationTimestamp = decodedPolicy.epochTime;
    return new Date(expirationTimestamp * 1000);
  }

  scheduleTokenRefresh(expirationDate: Date) {
    const currentTime = new Date().getTime();
    const expirationTime = expirationDate.getTime();
    const refreshTime = expirationTime - currentTime - 60000;

    if (this.refreshTokenTimeout) {
      clearTimeout(this.refreshTokenTimeout);
    }

    if (refreshTime > 0) {
      this.refreshTokenTimeout = setTimeout(() => {
        this.getSignedCookies();
      }, refreshTime);
    }
  }

  clearTokenRefreshTimer() {
    if (this.refreshTokenTimeout) {
      clearTimeout(this.refreshTokenTimeout);
    }
  }

  getPolicy(): string {
    return this.cloudFrontPolicy;
  }

  getSignature(): string {
    return this.cloudFrontSignature;
  }

  getKeyPairId(): string {
    return this.cloudFrontKeyPairId;
  }

  decodeToken(token: string) {
    return jwtDecode(token);
  }
  extractCloudFrontUrl(baseUrlCloudFront, fileName: string): string {
    const resourceUrl = extractValuesFromJson(baseUrlCloudFront);
    return `${resourceUrl}${fileName}`;
  }
}
